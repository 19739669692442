:root {
  --white: #ffffff;
  --primary-color: #007bff; /* Adjust this to match your primary color */
}

.product-card {
  background-color: var(--white);
  /* border-radius: 8px; */
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
}

.image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.product-image-link {
  display: block;
  width: 100%;
  height: 100%;
}

.product-image {
  width: 100%;
  /* height: 100%; */
  aspect-ratio: 1/1;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.product-image.loaded {
  opacity: 1;
}

.product-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.product-title {
  font-size: 1.1rem;
  margin-bottom: 5px;
  /* padding-left: 10px; */
  padding: 0px 10px 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  /* padding-left: 10px; */
  padding: 0px 10px 0px 10px;
}

.whatsapp-btn {
  background-color: #25D366;
  color: var(--white);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* Set a fixed height for consistency */
}

.whatsapp-btn:hover {
  background-color: #128C7E;
}

.whatsapp-btn.skeleton-button {
  background-color: #e0e0e0;
  color: transparent;
  cursor: default;
  animation: pulse 1.5s infinite;
}

.whatsapp-btn.skeleton-button:hover {
  background-color: #e0e0e0;
}


/* Skeleton loading styles */
.skeleton {
  background-color: #f0f0f0;
  animation: pulse 1.5s infinite;
}

.skeleton-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite;
}

.skeleton-text {
  height: 1em;
  margin-bottom: 8px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite;
  width: 100%;
}

.skeleton-button {
  width: 100%;
  height: 36px;
  background-color: #e0e0e0;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.hidden {
  display: none;
}