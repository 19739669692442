/* FAQ.css */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4;
  }
  
  .faq-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    color: #2c3e50;
  }
  
  .faq-item {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .faq-question {
    font-weight: bold;
    padding: 20px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f9f9f9;
  }
  
  .faq-question::after {
    content: '+';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
  }
  
  .faq-answer {
    padding: 0 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .faq-item.active .faq-question::after {
    content: '-';
  }
  
  .faq-item.active .faq-answer {
    padding: 20px;
    max-height: 1000px;
  }