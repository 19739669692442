/* footer {
    background-color: #333;
    color: var(--white);
    padding: 40px 0;
  }
  
  .footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    color: var(--secondary-color);
  }
  
  .footer-section ul {
    list-style-type: none;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: var(--white);
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: var(--secondary-color);
  }
  
  .social-icons a {
    color: var(--white);
    font-size: 1.5rem;
    margin-right: 15px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: var(--secondary-color);
  } */

  /* Footer.css */
:root {
  --white: #ffffff;
  --secondary-color: #ffd700; /* Adjust this to match your color scheme */
}

.site-footer {
  background-color: #333;
  color: var(--white);
  padding: 40px 0;
  flex-shrink: 0;
  font-family: "cinzel";
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.footer-section h3 {
  margin-bottom: 15px;
  color: var(--secondary-color);
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: var(--secondary-color);
}

.social-icons a {
  color: var(--white);
  font-size: 1.5rem;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
  }
}