:root {
  --white: #ffffff;
  --primary-color: #007bff;
  --text-color: #333333;
}

header {
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
}

.header-actions {
  display: flex;
  gap: 18px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
  text-decoration: none;
}

.search-bar {
  flex-grow: 1;
  margin: 0 20px;
  display: flex;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  font-size: 1rem;
}

.search-btn {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 0px 15px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.header-actions a {
  margin-left: 15px;
  color: var(--text-color);
  text-decoration: none;
}

.header-actions .sidebar-icon,
.header-actions  {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 10px 0;
  font-family: 'cinzel';
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: var(--primary-color);
}

/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: var(--white);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'cinzel';
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar .close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s;
}

.sidebar ul li a:hover {
  color: var(--primary-color);
}

/* Search Overlay Styles */
.search-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.search-results {
  width: 100%;
  max-width: 600px;
  height: 100vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 5px;
}

.search-result-item {
  display: flex;
  /* align-items: center; */
  padding: 5px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-direction: column;
}

.search-result-item:hover {
  background-color: #f9f9f9;
}

.search-result-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
}

.search-result-info {
  display: flex;
  flex-direction: column;
}

.search-result-name {
  font-weight: bold;
}

.search-result-description {
  font-size: 14px;
  color: #666;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .search-bar {
    display: none;
  }

  nav ul li{
    margin-right: 14px;
  }

  .search-bar.open {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--white);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 10px;
  }

  .search-bar.open input {
    width: 100%;
    border-radius: 4px;
  }

  .search-icon {
    display: block;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}


/* Desktop Styles */
@media (min-width: 769px) {
  .search-icon {
    display: none;
  }
}
