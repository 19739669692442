/* Styles for login page */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0);
    padding: 0 20px;
  }
  
  .login-form-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .login-title, .reset-password-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  .login-form, .reset-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-form input, .reset-password-form input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .login-form button, .reset-password-form button {
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-form button:hover, .reset-password-form button:hover {
    background-color: #0056b3;
  }
  
  .forgot-password-container {
    text-align: center;
    margin-top: 10px;
  }
  
  .forgot-password-button {
    background: none;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .reset-password-container {
    margin-top: 20px;
  }
  
  .back-to-login-button {
    background: none;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 20px;
  }
  
  .error {
    color: #dc3545;
    font-size: 14px;
    text-align: center;
  }
  
  .success {
    color: #28a745;
    font-size: 14px;
    text-align: center;
  }
  