/* ProductFilter.css */

.product-filter {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 25px;
}

/* Desktop Styles */
.desktop-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-section {
  flex: 1;
  min-width: 200px;
}

.price-range {
  display: flex;
  flex-direction: column;
}

.price-range input[type="range"] {
  width: 100%;
  margin-top: 5px;
}

.subcategory select,
.sort select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.filter-summary {
  flex-basis: 100%;
  text-align: right;
  font-size: 0.9em;
  color: #666;
}

/* Mobile Styles */
.mobile-filter {
  display: none;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px; 
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    
  }
  
  input[type="range"]:hover {
    opacity: 1;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px; /* Increased size */
    height: 25px; /* Increased size */
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 25px; /* Increased size */
    height: 25px; /* Increased size */
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }

input[type="checkbox"]:checked {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

input[type="checkbox"]:checked::before {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 12px;
}


.subcategory-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.subcategory-button {
  padding: 0.6rem 1.2rem;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-family: 'Arial', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subcategory-button.selected {
  background-color: #2c3e50;
  color: #ffffff;
  border-color: #2c3e50;
}

.subcategory-button:hover {
  background-color: #f8f8f8;
  border-color: #d0d0d0;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.subcategory-button.selected:hover {
  background-color: #34495e;
  border-color: #34495e;
}

@media (max-width: 768px) {
  .subcategory-boxes {
    justify-content: center;
  }
  
  .subcategory-button {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .desktop-filter {
    display: none;
  }

  .product-filter{
    box-shadow: none;
    padding: 0;
  }

  .mobile-filter {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }

  .filter-content {
    padding: 15px;
    max-height: 70vh;
    overflow-y: auto;
  }

  .filter-options {
    margin-bottom: 15px;
  }

  .filter-section {
    margin-bottom: 15px;
  }

  .filter-actions {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid #eee;
  }

  .filter-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }

  .filter-actions button:first-child {
    background-color: #f0f0f0;
  }

  .filter-actions button:last-child {
    background-color: #25D366;
    color: white;
  }
}