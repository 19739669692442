 .featured-products {
  padding: 20px 0px 40px 0px;
  }
  
  .featured-products h2 {
    text-align: center;
    font-family: "Cinzel";
    font-size: 30px;
    margin: 0px;
    font-weight: bolder;
    color: #454545;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
  } 

  main section{
    margin-bottom: 20px;
  }


  .banner-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background-color: #f8f8f8;
    flex-wrap: wrap;
    box-shadow: 1px 0px 3px 0px lightgray;
  }
  
  .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
    flex: 1;
    min-width: 120px;
  }
  
  .feature-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .feature-text {
    font-size: 0.8rem;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    /* .banner-container {
      flex-direction: column;
    } */
  
    .feature-item {
      width: 100%;
      margin: 10px 0;
    }
  }