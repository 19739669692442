/* :root {
  --primary-color: #4CAF50;
  --secondary-color: #FFC107;
  --text-color: #333;
  --bg-color: #f8f8f8;
  --white: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--bg-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.btn:hover {
  background-color: #45a049;
} */


@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');


:root {
  --primary-color: #4CAF50;
  --secondary-color: #FFC107;
  --text-color: #333;
  --bg-color: #f8f8f8;
  --white: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--bg-color);
}

#root {
  height: 100%;
}

.site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.site-content {
  flex: 1 0 auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn {
  display: inline-block;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 20px;
}

.btn:hover {
  background-color: #45a049;
}