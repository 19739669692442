/* Admin.css */

.admin-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.form-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-family: "cinzel";
  font-weight: lighter;
  font-size: 25px;
}

.input-group {
  margin-bottom: 20px;
}

input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

.upload-label {
  display: inline-block;
  padding: 12px 20px;
  background-color: #218838;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-label:hover {
  background-color: #0056b3;
}

#image-upload {
  display: none;
}

select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.sizes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.size-label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.add-product-button {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-product-button:hover {
  background-color: #218838;
}

.product-list {
  background-color: #fff;
  border-radius: 8px;
  /* padding: 30px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.product-list h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}

.product-list ul {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}

.product-list li {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
}

.product-list li:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.product-info {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  font-family: 'Roboto';
  font-weight: lighter;
}
.product-info h1{
  font-family: "Italiana"
}

.product-info img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-info h3 {
  font-size: 18px;
  font-family: 'italiana';
  color: #333;
  margin: 0;
  margin-top: 3px;
}

.product-info p {
  margin: 5px 0;
  color: #666;
}

.product-info .description {
  font-size: 14px;
  color: #555;
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.product-info .price {
  font-weight: bold;
  color: #28a745;
}

.product-info .rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-info .rating span {
  color: #ffc107;
}

.product-info .sizes {
  font-size: 14px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-end;
  margin-top: 10px;
}

.delete-button:hover {
  background-color: #c82333;
}

.edit-button {
  background: rgb(0, 123, 255);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.cancel-edit-button {
  background-color: #999;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 13px;
}

.action-btn-admin{
 display: flex;
 justify-content: space-between;
}


.cancel-edit-button {
  background-color: #999;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}


.categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
}

.category-label {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.category-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.category-label:hover input ~ .checkmark {
  background-color: #ccc;
}

.category-label input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.category-label input:checked ~ .checkmark:after {
  display: block;
}

.category-label .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.seo-section .input-group {
  margin-bottom: 15px;
}

.seo-section input[type="text"],
.seo-section textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

.seo-section textarea {
  resize: vertical;
}

.seo-section input[type="text"]:focus,
.seo-section textarea:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


@media (max-width: 768px) {
  .product-list ul {
    grid-template-columns: 1fr;
  } 
  .product-info h3 {
    font-size: 15px;
  }
}