.product-list {
  /* padding: 40px 0; */
  padding-top: 20px;
}

.product-list h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-family: "cinzel";
  font-weight: lighter;
  font-size: 25px;
  font-weight: bold;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;  /* Adjust this value as needed */
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;  /* Change this color as desired */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
    /* gap: 9px; */
    gap: 1px;
    /* background-color: lightgray; */
  }

  #productbox{
  margin: 0;
  padding: 0;
  }
}
