.sliding-banner {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
  
  .sliding-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
  }
  
  .banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .banner-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .banner-content .btn {
    font-size: 1.2rem;
    padding: 10px 30px;
  }


  @media (max-width: 768px) {
  .sliding-banner{
    height: 200px;
  }
  }