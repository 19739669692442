.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #388e3c;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  text-align: center;
  color: #e74c3c;
  font-size: 1.2em;
  margin-top: 20px;
}

.product-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.product-detail-content {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.product-images {
  flex: 1;
  min-width: 300px;
}

.main-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.thumbnail-images {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.thumbnail-images img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}

.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-price {
  font-size: 28px;
  font-weight: bold;
  color: #1a1a1a;
  margin-bottom: 15px;
}

.product-rating {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.rating {
  background-color: #388e3c;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 10px;
}

.reviews {
  color: #666;
  font-size: 14px;
}

.delivery-info {
  color: #388e3c;
  font-weight: bold;
  margin-bottom: 20px;
}

.size-selection h3,
.product-details h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.size-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.size-btn {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.size-btn.selected,
.size-btn:hover {
  background-color: #1a1a1a;
  color: white;
}

.product-details ul {
  list-style-type: none;
  padding: 0;
}

.product-details li {
  margin-bottom: 5px;
}

.whatsapp-btn {
  display: inline-flex;
  display:none;
  align-items: center;
  background-color: #25D366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.whatsapp-btn:hover {
  background-color: #128C7E;
}

.whatsapp-btn i {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .product-detail-content {
    flex-direction: column;
  }

  .product-images,
  .product-info {
    width: 100%;
  }

  h1 {
    font-size: 20px;
  }

  .product-price {
    font-size: 14px;
    margin: 0px;
    font-family:'Roboto';
    

  }

  .size-options {
    flex-wrap: wrap;
  }
}
